@import './src/assets/styles/variables/colors';

.wrapper {
  display: block;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
  line-height: 18px;
  color: $white-80;
  letter-spacing: -0.015em;
  text-transform: capitalize;
}

.label {
  display: flex;
  align-items: center;
}

.input {
  position: absolute;
  cursor: pointer;
  height: 0;
  width: 0;
  left: -10000px;

  &:checked {
    ~ .radio {
      background-color: $pink;

      &::after {
        opacity: 1;
      }
    }
  }
}

.radio {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background: $grey-input;
  border-radius: 50%;

  &::after {
    content: '';
    position: absolute;
    opacity: 0;
    top: 25%;
    left: 25%;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $black-nav-bg;
  }
}
