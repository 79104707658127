@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.chartBlock {
  width: 100%;
  padding-left: 24px;
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  border-left: 1px dashed $white-5;

  @include lg {
    padding-left: 0;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    border-left: none;
  }

  @include md {
    grid-row-start: 3;
  }
}
