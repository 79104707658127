.contentCenter {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;
  max-width: 334px;
}
