@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background: $white-5;
  border-radius: 16px;
}

.heading {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white-50;
}

.valueWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.valueHeading {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: $white-50;
}

.value {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: $white-90;
}
