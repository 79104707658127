@mixin xxxxl {
  @media (max-width: 1920px) {
    @content;
  }
}

@mixin xxxl {
  @media (max-width: 1700px) {
    @content;
  }
}

@mixin xxl {
  @media (max-width: 1400px) {
    @content;
  }
}

@mixin xl {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin lg {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin md {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin sm {
  @media (max-width: 576px) {
    @content;
  }
}
