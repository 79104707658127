@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.burgerMenu {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wrapper {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
}

.contentWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.btnWrapper {
  width: 100%;
  height: 46px;
}

.buttonsBlock {
  position: relative;
  height: 100%;
  min-height: 46px;
  width: 100%;

  display: flex;
  gap: 8px;
  overflow: hidden;
}

.payment {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include sm {
    top: unset;
    bottom: 0;
    transform: translate(-50%, 0);

    height: 80vh;
    overflow-y: scroll;

    iframe {
      width: 100vh !important;
    }
  }
}
