@import 'fonts';
@import 'components/modal';
@import 'components/select';
@import 'components/scrollbar';
@import 'components/toast';

* {
  margin: 0;
  padding: 0;
  font-family: Oxanium, sans-serif !important;
}

body {
  overflow-y: overlay;
}

button {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

:focus-visible {
  outline: none !important;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 10000;
}
