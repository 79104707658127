@import './src/assets/styles/variables/colors';

.wrapper {
  min-height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 1px solid $white-4;
}

.logoText {
  margin-right: auto;
  font-size: 22px;
  font-weight: 900;
  letter-spacing: 1px;
  color: $white;
}

.logoIcon {
  position: relative;
  z-index: 1001;

  img {
    height: 70px;
  }
}
