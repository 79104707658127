@import './src/assets/styles/variables/colors';

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.heading {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white-50;
}
