@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  height: 100%;
  display: flex;
}

.contentWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background: $black-30;
  backdrop-filter: blur(50px);
  border: 1px solid $black-15;
  border-radius: 32px;
  transition: all 500ms;

  @include xl {
    gap: 16px;
  }
}

.text {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: $white-50;

  @include xl {
    font-size: 12px;
    line-height: 15px;
  }
}
