@import './src/assets/styles/variables/colors';

.wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
}

.input {
  position: relative;
  height: 16px;
  padding-left: 14px;
  color: transparent;
  border: 1px solid $grey-input;
  border-radius: 2px;
  appearance: none;
  outline: 0;
  cursor: pointer;

  &::before,
  &::after {
    position: absolute;
    content: '';
    display: block;
    width: 0;
    background: white;
    border-style: solid;
    border-color: $white;
    border-width: 1px;
    border-radius: 16px;
    opacity: 0;
  }

  &::before {
    display: block;
    top: 5px;
    left: 3px;
    height: 5px;
    transform: rotate(315deg);
  }

  &::after {
    top: 2px;
    left: 7px;
    height: 9px;
    transform: rotate(45deg);
  }

  &:checked {
    color: $white;
    border-color: $pink;
    background: $pink;

    &::before,
    &::after {
      opacity: 1;
    }
  }
}
