@import './src/assets/styles/variables/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.date {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.015em;
  color: $white-50;
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.amountWrapper {
  display: flex;
  gap: 8px;
}

.amount {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white-80;
}

.iconWrapper {
  display: flex;
  gap: 4px;
}

.exchangeWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.name {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: $white-80;
}
