@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  position: relative;
  width: 100%;
  //max-width: 200px;
  padding-bottom: 18px;
  text-align: center;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0;
    border: 1px solid $white-10;
    border-radius: 2px;
  }

  @include sm {
    //max-width: 97px;
    padding-bottom: 14px;
  }
}

.wrapperActive:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  border: 1px solid $pink;
  border-radius: 2px;
}

.button {
  cursor: pointer;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: $white-80;
  letter-spacing: -0.015em;
  text-transform: uppercase;
  background: none;
  border: none;
  outline: none;

  @include xl {
    font-size: 16px;
    line-height: 20px;
  }
}

.buttonActive {
  color: $white;
}
