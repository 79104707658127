@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  display: flex;
}

.contentWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  backdrop-filter: blur(50px);
  border: 1px solid $black-15;
  border-radius: 32px;
  transition: all 500ms;
}

.text,
.link {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;

  @include xl {
    font-size: 12px;
    line-height: 15px;
  }
}

@media screen and (max-width: 600px) {
  table {
    font-size: 14px;
  }
}

.text {
  color: $white-50;
}

.link {
  color: $pink;
}

.columnHead {
  font-weight: 300;
  font-size: 16px;
  letter-spacing: -0.015em;
  color: $white-80;
}

.columnHeadWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px;
}

.rowHead {
  font-weight: 300;
  font-size: 12px;
  letter-spacing: -0.015em;
  color: $white-50;
}

.text {
  font-weight: 300;
  font-size: 12px;
  letter-spacing: -0.015em;
  color: $white-80;
}

.table {
  border-spacing: 0 24px;
  width: 100%;
}

.column {
}

.value {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.015em;
  text-align: center;
  color: $white;
  padding: 8px;
}
