@import './src/assets/styles/variables/colors';

.wrapper {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: $white-5;
  border-radius: 16px;
}

.heading {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white-50;
}

.poolValuesWrapper {
  display: flex;
  justify-content: space-between;
}
