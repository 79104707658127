@import './src/assets/styles/variables/colors';

.activeLink {
  .wrapper {
    width: 100%;
    background: linear-gradient(0deg, $pink-10, $pink-10), $black-nav-bg;
    border-radius: 16px;
  }

  .label {
    color: $white;
  }

  path {
    stroke: $pink;
  }
}

.link:nth-child(4),
.link:last-child {
  .icon svg path {
    fill: $grey-nav;
  }
}

.link:nth-child(4).activeLink {
  .icon svg path {
    fill: $pink;
  }
}

.wrapper {
  padding: 16px 24px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.label {
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $grey-nav;
}
