@import './src/assets/styles/variables/colors';

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navWrapper {
  width: 100%;
  max-width: 400px;
  display: flex;
}
