@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  background: $black-30;
  border: 1px solid $black-15;
  backdrop-filter: blur(50px);
  border-radius: 32px;

  @include lg {
    padding: 16px;
  }
}

.contentWrapper {
  height: 100%;
}

.widthSmall {
  max-width: calc(100vw - 1044px);
  min-width: 1048px;

  @include xxxxl {
    max-width: calc(100vw - 870px);
    min-width: unset;
  }

  @include xxxl {
    max-width: 100%;
  }

  @include xxl {
    max-width: 100%;
  }
}

.widthFull {
  max-width: calc(100vw - 1044px);
  min-width: 1048px;

  @include xxxxl {
    max-width: calc(100vw - 870px);
    min-width: unset;
  }

  @include xxxl {
    max-width: 840px;
  }

  @include xxl {
    max-width: 100%;
  }
}
