@import './src/assets/styles/variables/colors';

.button {
  padding: 4px;
  width: 44px;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: $white-80;
  background: $white-3;
  border-radius: 12px;
}
