@import './src/assets/styles/mixin/breakpoints';

.gridWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;

  @include md {
    grid-template-columns: 1fr;
  }
}

.inputWrapper {
  width: 100%;
  display: flex;
  gap: 24px;

  @include xxxxl {
    display: grid;
    grid-template-columns: 1fr 4fr;
  }

  @include md {
    grid-template-columns: 1fr;
  }
}

.totalWrapper {
  width: 100%;
  max-width: 334px;
}

.inputValueWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @include md {
    grid-template-columns: 1fr;
  }
}
