@import '../../../../../../../../../assets/styles/variables/colors';
@import '../../../../../../../../../assets/styles/mixin/breakpoints';

.wrapper {
  display: flex;

  @include md {
    width: 100%;
  }
}

.input {
  position: absolute;
  visibility: hidden;
}

.label {
  cursor: pointer;
  display: flex;
  text-align: center;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: -0.015em;
  color: $white-50;
  padding: 4px 12px;

  @include md {
    width: 100%;
    padding: 4px 0;
    justify-content: center;
  }
}

.input:checked ~ .label {
  color: $white;
  background: $white-5;
  border-radius: 8px;
}
