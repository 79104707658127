@import './src/assets/styles/variables/colors';

.moduleBorderWrap {
  max-width: 100%;
  position: relative;
  background: linear-gradient(to right, #ff136d, #453fd6);
  padding: 1px;
  border-radius: 16px;
}

.button {
  padding: 18px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: $black-bg;
  opacity: 1;
  transition: 0.8s;

  &:hover {
    transition: 0.8s;
    opacity: 0.93;
  }
}

.text {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white-90;
}

.icon {
  display: flex;
}
