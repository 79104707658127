@import '../../assets/styles/variables/colors';

.wrapper {
  padding: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  background: $orange-5;
  border-radius: 16px;
}

.text {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: $orange;
  white-space: pre-wrap;
}
