@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
}

.icon {
  display: flex;
  padding: 8px;
  background: $blue;
  box-shadow: 0 0 30px $blue-btn-40;
  border-radius: 16px;
}

.text {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white-50;

  @include xl {
    font-size: 12px;
    line-height: 15px;
  }
}
