@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include lg {
    padding: 15px;
  }
}

.btnBlock {
  display: flex;
  gap: 24px;

  @include lg {
    display: none;
  }
}

.btnWrapper {
  width: 200px;
}

.icon {
  width: 70px;
  height: 70px;
  display: flex;
}

.logoText {
  display: none;
  font-weight: 800;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.015em;
  color: $white;

  @include lg {
    display: block;
  }
}

.logoBlock {
  display: flex;
  align-items: center;
  gap: 8px;
}
