@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.overlay-custom {
  position: fixed;
  inset: 0;
  background: $black-30;
  backdrop-filter: blur(5px);
  z-index: 2;
}

.content-custom {
  padding: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $black-bg;
  border: 1px solid $black-15;
  border-radius: 32px;
  overflow: auto;
  outline: none;
}

.overlay-side,
.overlay-side-burger {
  background: $black-30;
  backdrop-filter: blur(5px);
}

.overlay-side {
  z-index: 2;
}

.overlay-side-burger {
  z-index: 1;
}

.modal-wrapper {
  width: 480px !important;
  height: calc(100% - 64px) !important;
  margin-top: 32px;
  margin-right: 32px;
  background: $black-bg !important;
  border: 1px solid $black-15;
  border-radius: 32px;
  box-shadow: 0 30px 60px $black-5;

  .slide-pane__content {
    padding: 24px;

    @include md {
      padding: 32px;
    }
  }

  @include md {
    position: absolute;
    bottom: 0;
    width: 100% !important;
    height: fit-content !important;
    margin-top: 0;
    margin-right: 0;
    border-radius: 32px 32px 0 0;
  }
}

.modal-burger {
  width: 100%;
  max-width: 360px;
  background: $black-bg !important;
  border: 1px solid $black-30;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;

  .slide-pane__content {
    padding: 0;
  }

  @include sm {
    max-width: 100%;
    width: 100% !important;
  }
}
