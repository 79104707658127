@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  padding: 16px;
  display: flex;
  gap: 24px;
  background: $black-30;
  border-radius: 12px;

  @include md {
    background: none;
  }
}

.cardWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.textWrapper {
  display: flex;
  align-items: center;
  gap: 4px;

  @include sm {
    flex-direction: column;
    align-items: flex-start;
  }
}

.headingWrapper {
  margin-left: 16px;
}

.heading,
.measure {
  font-weight: 300;
  line-height: 15px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white-50;
}

.heading {
  font-size: 12px;
}

.measure {
  font-size: 14px;
}

.textWhite {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: $white;

  @include xl {
    font-size: 14px;
    line-height: 18px;
  }
}
