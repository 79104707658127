@import './src/assets/styles/variables/colors';

.wrapper {
  display: flex;
  gap: 10px;
  height: 275px;

  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}

.chartContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.legendWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.legendItem {
  font-weight: 300;
  font-size: 12px;
  letter-spacing: -0.015em;
  color: $white-50;
}
