@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  height: 100%;
  display: flex;
}

.contentWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background: $black-30;
  backdrop-filter: blur(50px);
  border: 1px solid $black-15;
  border-radius: 32px;
  transition: all 500ms;

  @include xl {
    gap: 16px;
  }
}
