@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.img {
  @include xxl {
    width: 600px;
  }

  @include xl {
    width: 400px;
  }

  @include xl {
    width: 330px;
  }
}

.headingBlock {
  padding: 0 190px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include xxxl {
    padding: 0 60px;
  }

  @include xl {
    padding: 0 30px;
  }

  @include lg {
    padding: 0 15px;
    flex-direction: column-reverse;
    justify-content: center;
    gap: 32px;
  }
}

.heading {
  font-weight: 800;
  font-size: 50px;
  line-height: 62px;
  letter-spacing: -0.015em;
  text-transform: uppercase;
  color: $white;

  @include lg {
    font-size: 24px;
    line-height: 30px;
  }
}

.text {
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.015em;
  color: $white-80;

  @include lg {
    font-size: 18px;
    line-height: 22px;
  }
}

.textWrapper {
  width: 330px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.btnBlock {
  display: none;
  height: 100%;
  width: 100%;
  padding: 32px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;

  @include lg {
    display: flex;
  }
}

.btnWrapper {
  width: 330px;
  height: 60px;
}
