@import './src/assets/styles/variables/colors';

.wrapper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $black-bg;
  //background-image: url("../../assets/images/bg-lines.png"), url("../../assets/images/page-bg-shadow.png");
  //background-position: left bottom, left top;
  //background-size: unset 100%;
  //background-repeat: no-repeat no-repeat, no-repeat no-repeat;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
  }

  & > div {
    z-index: 1;
  }

  &:before {
    background-image: url('../../assets/images/bg-lines.png');
    background-position: left bottom;
    background-repeat: repeat no-repeat;
  }

  &:after {
    background-image: url('../../assets/images/page-bg-shadow.png');
    background-position: left top;
    background-size: cover;
    background-repeat: no-repeat no-repeat;
  }
}
