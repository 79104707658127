@import './src/assets/styles/mixin/breakpoints';

.videoWrapper {
  position: relative;
  z-index: 3;
  overflow: hidden;
  padding-bottom: 56.25%;
  height: 0;
  width: 600px;

  @include md {
    width: unset;
  }

  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}
