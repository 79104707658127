@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  height: 100%;
  display: flex;
  background: url('../../../assets/images/token-creator-rocks.png') no-repeat top right;
  background-size: contain;

  @include lg {
    background: none;
  }
}

.inputsBlock {
  display: flex;
  gap: 24px;

  @include xl {
    gap: 12px;
  }

  @include md {
    flex-direction: column;
    gap: 8px;
  }
}

.visualBlock {
  margin-top: 19px;
  grid-column-start: 2;
  grid-column-end: 4;
}

.contentWrapper {
  width: 100%;
  padding: 24px;
  grid-column-start: 1;
  display: flex;
  flex-direction: column;
  gap: 48px;
  background: $black-30;
  backdrop-filter: blur(50px);
  border: 1px solid $black-15;
  border-radius: 32px;
  transition: all 500ms;

  @include lg {
    gap: 24px;
  }
}

.widthSmall {
  max-width: calc(100vw - 1044px);
  min-width: 1048px;

  @include xxxxl {
    max-width: calc(100vw - 870px);
    min-width: unset;
  }

  @include xxxl {
    max-width: 100%;
  }

  @include xxl {
    max-width: 100%;
  }
}

.widthFull {
  max-width: calc(100vw - 1044px);
  min-width: 1048px;

  @include xxxxl {
    max-width: calc(100vw - 870px);
    min-width: unset;
  }

  @include xxxl {
    max-width: 840px;
  }

  @include xxl {
    max-width: 100%;
  }
}

.divider {
  width: 100%;
  height: 100%;
  padding-left: 24px;
  border-left: 1px dashed $white-5;

  @include md {
    padding-left: 0;
    padding: 8px 0;
    border-left: none;
    border-top: 1px dashed $white-5;
    border-bottom: 1px dashed $white-5;
  }
}
