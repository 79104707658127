@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.overlayCustom {
  position: fixed;
  inset: 0;
  background: $black-30;
  backdrop-filter: blur(3px);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalWrapper {
  display: flex;
  width: 520px !important;
  min-height: 300px !important;
  max-height: calc(100% - 64px) !important;
  margin-top: 32px;
  margin-right: 32px;
  background: $black-bg !important;
  border: 1px solid $black-15;
  border-radius: 32px;
  box-shadow: 0 30px 60px $black-5;
  color: $white;

  @include md {
    width: 90% !important;
    height: fit-content !important;
    margin-top: 0;
    margin-right: 0;
  }
}

.wrapper {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  gap: 24px;
}

.headingWrapper {
  padding: 12px 0;
  border-bottom: 1px solid $white-5;
  text-align: center;
}

.heading {
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.015em;
  text-transform: capitalize;

  @include md {
    font-size: 18px;
  }
}

.content {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  padding: 0 24px 24px;
}

.message {
  flex: 1;
  color: $white-50;

  &_title {
    color: $white;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  &_link {
    color: $white;
  }
}

.action {
  height: 46px;
  margin-top: 16px;
}
