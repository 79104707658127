@import './src/assets/styles/variables/colors';

.wrapper {
  width: 100%;
}

.button {
  display: flex;
  align-items: center;
}

.label {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.015em;
  color: $pink;
}

.icon {
  display: flex;
}

.text {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  padding: 0 5px;
  letter-spacing: -0.015em;
  color: $white-80;
}
