@import '../../../../../../../../assets/styles/variables/colors';
@import '../../../../../../../../assets/styles/mixin/breakpoints';

.wrapper {
  padding: 2px;
  display: flex;
  background: $white-5;
  border-radius: 8px;

  @include md {
    width: 100%;
  }
}
