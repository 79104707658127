:root {
  --toastify-color-light: #100f0f !important;
  --toastify-text-color-light: #ffffff !important;
  --toastify-icon-color-success: #ff136d !important;
  --toastify-color-progress-success: linear-gradient(264.11deg, #ff136d -2.87%, #443de9 105.13%) !important;
  --toastify-icon-color-error: #ff136d !important;
  --toastify-color-progress-error: linear-gradient(264.11deg, #ff136d -2.87%, #443de9 105.13%) !important;
}

.Toastify__toast {
  border-radius: 16px !important;
}

.Toastify__close-button > svg {
  fill: #ffffff !important;
}
