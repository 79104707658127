@import '../../../../../../../../../assets/styles/variables/colors';
@import '../../../../../../../../../assets/styles/mixin/breakpoints';

.wrapper {
  padding: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  background: $white-3;
  border-radius: 12px;

  //@include md {
  //  display: none;
  //}
}

.iconsWrapper {
  display: flex;
  gap: 4px;
  align-items: center;
}

.iconsWrapperReverse {
  flex-direction: row-reverse;
}

.icon {
  display: flex;
}

.switchIcon {
  display: flex;

  path {
    stroke: $pink;
  }
}

.textWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.textWrapperReverse {
  flex-direction: row-reverse;
}

.text {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: $white-80;
}
