@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  padding: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 24px;
  background: $black-30;
  border: 1px solid $black-15;
  border-radius: 32px;
  backdrop-filter: blur(50px);

  @include xl {
    padding: 16px;
  }

  @include md {
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
}
