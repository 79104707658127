@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.heading {
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white;

  @include xl {
    font-size: 18px;
    line-height: 22px;
  }
}
