@import './src/assets/styles/variables/colors';

.wrapper {
  padding-top: 24px;
  display: flex;
  align-items: center;
  gap: 4px;
  border-top: 1px dashed $white-10;
}

.text {
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: $white-90;
}
