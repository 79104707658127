@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include lg {
    display: none;
  }
}

.heading {
  font-weight: 800;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white;
}

.btnWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.buttonsBlock {
  position: relative;
  height: 100%;
  min-height: 46px;
  width: 100%;
  max-width: 215px;
}

.buyBtn {
  position: absolute;
  left: -160px;
}

.payment {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include sm {
    top: unset;
    bottom: 0;
    transform: translate(-50%, 0);

    height: 80vh;
    overflow-y: scroll;

    iframe {
      width: 100vh !important;
    }
  }
}
