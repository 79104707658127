@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  background: $black-30;
  border-radius: 16px;

  @include xl {
    padding: 16px;
    gap: 8px;
  }

  @include md {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
