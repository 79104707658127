@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding: 24px;
  width: 100%;
  max-height: calc(100vh - 64px);
  background: $black-bg;
  border: 1px solid $black-30;
  border-radius: 16px;
  transition: all 500ms;

  @include lg {
    display: none;
  }
}

.widthFull {
  max-width: 276px;
}

.widthSmall {
  max-width: 112px;
}
