@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;

  @include md {
    grid-template-columns: 1fr;
  }
}

.checkboxBlock {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(3, 1fr);
  grid-column-start: 1;
  grid-column-end: 3;

  @include md {
    grid-template-columns: 1fr;
    grid-column-start: unset;
    grid-column-end: unset;
  }
}

.checkboxWrapper {
  &:nth-child(2) {
    grid-column-start: 2;

    @include lg {
      grid-column-start: 3;
    }

    @include md {
      grid-column-start: unset;
    }
  }

  &:last-child {
    grid-column-start: 1;
    grid-column-end: 3;

    @include md {
      grid-column-start: unset;
      grid-column-end: unset;
    }
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.text {
  font-weight: 300;
  font-size: 16px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white-80;
}

.subtext {
  font-weight: 300;
  font-size: 12px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white-50;
}

.link {
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.015em;
  color: $pink;
}
