@import './src/assets/styles/variables/colors';

.wrapper {
  position: relative;
  width: 100%;
  height: 64px;
}

.input {
  width: 100%;
  height: 100%;
  padding: 16px;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white-50;
  background-color: transparent;
  border-radius: 16px;
  border: 1px solid $grey-input;
  outline: none;
  transition: 0.8s;

  &:not([value='']) {
    font-weight: 500;
    color: $white;
  }

  &:hover {
    padding: 17px;
    font-weight: 500;
    color: $white-70;
    border: 1px solid $grey-nav;
    transition: 0.8s;
  }

  &:focus {
    padding: 16px;
    font-weight: 500;
    color: $white;
    border: 1px solid $grey-nav;
    transition: 0.8s;
  }

  &::placeholder {
    font-weight: 300;
    color: $white-50;
  }
}

.inputError {
  border: 1px solid $red;

  &:hover {
    border: 1px solid $red;
  }

  &:focus {
    border: 1px solid $red;
  }
}
