@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include lg {
    justify-content: center;
  }
}

.totalList {
  width: 100%;
  max-width: 334px;

  @include lg {
    max-width: 100%;
  }
}

.btnWrapper {
  width: 100%;
  max-width: 334px;
  height: 57px;

  @include lg {
    align-self: center;
  }

  @include sm {
    max-width: 100%;
  }
}
