@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  min-width: 400px;
  max-height: calc(100vh - 100px);
  min-height: fit-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include md {
    min-width: auto;
    width: calc(100vw - 120px);
  }
}

.headingWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headingText {
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white;

  @include md {
    font-size: 18px;
  }
}
