@import '../../../../../assets/styles/variables/colors';

.headingWrapper {
  display: flex;
  justify-content: center;
}

.btnWrapper {
  height: 57px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.inputWrapperReverse {
  flex-direction: column-reverse;
}

.button {
  padding: 4px 8px;
  display: flex;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
}
