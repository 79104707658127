@import './src/assets/styles/variables/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.heading {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: $white-50;
}

.value {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: $white-90;
}
