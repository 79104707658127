@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: $white-50;

  @include xl {
    font-size: 12px;
    line-height: 15px;
  }
}

.valueWrapper {
  display: flex;
  gap: 4px;
}

.value {
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.015em;
  color: $white;

  @include xl {
    font-size: 16px;
    line-height: 20px;
  }
}

.measure {
  font-weight: 800;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.015em;
  color: $white-50;

  @include xl {
    font-size: 14px;
    line-height: 20px;
  }
}
