@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.cardWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;

  @include sm {
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
  }
}
