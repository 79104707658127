@import './src/assets/styles/variables/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.headingWrapper {
  display: flex;
  justify-content: center;
}

.text {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white-50;
}
