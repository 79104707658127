@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.modalWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.subheading {
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white-80;

  @include xl {
    font-size: 16px;
    line-height: 20px;
  }
}

.valueGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;

  @include md {
    grid-template-columns: 1fr;
  }
}

.valueWrapper,
.valueContractWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.valueContractWrapper {
  @include xl {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
}

.valueHeading {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: $white-50;

  @include xl {
    font-size: 12px;
    line-height: 15px;
  }
}

.value,
.valueContract {
  font-weight: 800;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.015em;
  color: $white;

  @include xl {
    font-size: 16px;
    line-height: 20px;
  }
}

.valueContract {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @include xl {
    width: 100%;
  }
}
