@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.headingWrapper {
  display: flex;
  justify-content: space-between;
}

.headingText,
.value {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
}

.headingText {
  font-weight: 300;
  color: $white-50;
}

.valueSlider {
  font-weight: 700;
  color: $white;
}

.sliderButtonWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

.sliderButton {
  padding: 4px;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: $white-80;
  background: $white-3;
  border-radius: 12px;
}

.valueWrapper {
  display: flex;
  justify-content: space-between;
}

.value {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  text-align: right;
  color: $white;
}

.receiveButton {
  align-self: flex-end;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: $white-80;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.button {
  width: 100%;
  height: 57px;
}

.inputBlock {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.inputHeading {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white-50;
}

.wrapperModal {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include md {
    max-width: 100%;
  }
}

.valueModal {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: $white;
}

.buttonModal {
  width: 100%;
  height: 57px;

  @include md {
    max-width: 400px;
    align-self: center;
  }
}

.contentWrapperModal {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.modalLink {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: $white-80;

  @include xl {
    font-size: 14px;
  }
}

.error {
  margin-top: 8px;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.015em;
  text-align: right;
  text-transform: capitalize;
  color: $red;
}
