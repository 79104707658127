$white: #ffffff;
$white-3: rgba($white, 0.03);
$white-4: rgba($white, 0.04);
$white-5: rgba($white, 0.05);
$white-10: rgba($white, 0.1);
$white-30: rgba($white, 0.3);
$white-50: rgba($white, 0.5);
$white-70: rgba($white, 0.7);
$white-80: rgba($white, 0.8);
$white-90: rgba($white, 0.9);
$pink: #ff136d;
$pink-10: rgba($pink, 0.1);
$pink-15: rgba($pink, 0.15);
$black: #000000;
$black-5: rgba($black, 0.05);
$black-15: rgba($black, 0.15);
$black-26: rgba($black, 0.26);
$black-30: rgba($black, 0.3);
$black-70: rgba($black, 0.7);
$black-87: rgba($black, 0.87);
$black-bg: #100f0f;
$black-nav-bg: #080808;
$black-footer: #0a0a0a;
$blue: #443de9;
$blue-btn: #453fd6;
$blue-btn-15: rgba($blue-btn, 0.15);
$blue-btn-40: rgba($blue-btn, 0.4);
$grey-nav: #b6b6b6;
$grey-input: #414141;
$orange: #f5b300;
$orange-5: rgba($orange, 0.05);
$green: #12bc56;
$red: #d33030;
