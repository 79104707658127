@import './src/assets/styles/variables/colors';

.wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  align-self: center;
  gap: 12px;
}

.text {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  white-space: nowrap;
  color: $blue;
}

.arrow {
  transform: rotate(180deg);
}
