@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: $black-30;
  border-radius: 16px;

  @include xl {
    padding: 16px;
    gap: 8px;
  }

  @include md {
    padding: 0;
    background: transparent;
  }
}
