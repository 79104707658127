@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  padding: 12px 32px;
  display: flex;
  align-items: center;
  gap: 12px;
  background: $black-30;
  border-radius: 16px;

  @include lg {
    width: calc(100% - 158px);
    padding: 12px 9px 12px 32px;
    justify-content: flex-end;
  }
}

.walletId {
  max-width: 156px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white-90;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    max-width: 100%;
  }

  @include lg {
    max-width: 100%;
  }
}

.iconsWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.icon {
  &:hover path {
    stroke: $pink;
  }
}
