@import '../../../../../../../assets/styles/variables/colors';

.wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.label {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  text-align: left;
  text-transform: capitalize;
  color: $white-80;
}

.description {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.015em;
  text-align: left;
  text-transform: capitalize;
  color: $grey-nav;
}
