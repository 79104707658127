@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  padding-top: 24px;
  display: flex;
  gap: 24px;
  border-top: 1px dashed $white-5;

  @include md {
    flex-direction: column;
    gap: 16px;
    padding-top: 0;
    border-top: none;
  }
}

.cardWrapper {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  background: $black-30;
  border-radius: 16px;

  @include xl {
    padding: 16px;
    gap: 8px;
  }

  @include md {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.cardHeading {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: $white-50;

  @include xl {
    font-size: 12px;
  }
}

.buttonWrapper {
  height: 64px;
  min-width: 120px;
}
