@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  display: flex;
  flex-direction: column;
}

.labelWrapper {
  display: flex;
  justify-content: space-between;
}

.label {
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: $white-50;
}
