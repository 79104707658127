@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.radioWrapper {
  display: flex;
  gap: 24px;

  @include lg {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @include sm {
    grid-template-columns: 1fr;
  }
}
