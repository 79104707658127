@import './src/assets/styles/mixin/breakpoints';
@import './src/assets/styles/variables/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.gridWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;

  @include md {
    grid-template-columns: 1fr;
  }
}

.inputWrapper {
  display: flex;
  gap: 24px;

  @include md {
    flex-direction: column;
  }
}

.input {
  width: 100%;
}

.totalWrapper {
  width: 100%;
  max-width: 334px;

  @include lg {
    align-self: center;
  }

  @include sm {
    max-width: 100%;
  }
}

.btnWrapper {
  width: 100%;
  max-width: 334px;
  height: 57px;

  @include lg {
    align-self: center;
  }

  @include sm {
    max-width: 100%;
  }
}

.checkoutBlock {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.modalWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.subheading {
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white-80;

  @include xl {
    font-size: 16px;
    line-height: 20px;
  }
}

.valueGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;

  @include sm {
    grid-template-columns: 1fr;
  }
}

.valueWrapper,
.valueContractWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.valueContractWrapper {
  @include xl {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
}

.valueHeading {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: $white-50;

  @include xl {
    font-size: 12px;
    line-height: 15px;
  }
}

.value,
.valueContract {
  font-weight: 800;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.015em;
  color: $white;

  @include xl {
    font-size: 16px;
    line-height: 20px;
  }
}

.valueContract {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @include xl {
    width: 100%;
  }
}
