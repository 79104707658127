@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.btnWrapper {
  height: 57px;
}

.contentWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include md {
    gap: 24px;
  }
}

.inputListWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
