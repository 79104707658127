@import './src/assets/styles/variables/colors';

.balanceWrapper {
  margin-top: 8px;
  display: flex;
  justify-content: end;
  gap: 8px;
}

.balanceText {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white-50;
}

.button {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $pink;
}
