@import '../../../../../../../../assets/styles/variables/colors';
@import '../../../../../../../../assets/styles/mixin/breakpoints';

.wrapper {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.valueWrapper {
  display: flex;
  flex-direction: column;
}

.numbersWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.value {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white;

  @include md {
    font-size: 18px;
  }
}

.valueSmall {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white;

  @include md {
    font-size: 16px;
  }
}

.percent {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.015em;
  text-transform: capitalize;

  @include md {
    font-size: 14px;
  }
}

.percentPos {
  color: $green;
}

.percentNeg {
  color: $red;
}

.date {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white-50;
}
