@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  display: none;

  @include lg {
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-height: 57px;
    padding: 0 15px;
    background: $black-bg;
  }
}

.headerWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoWrapper {
  display: flex;
  align-items: center;
}

.logoIcon svg {
  width: 47px;
  height: 47px;
}

.logoText {
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white;
}

.walletWrapper {
  padding-bottom: 15px;
}

.iconActive path {
  stroke: $pink;
}
