@import '../../../../../../../assets/styles/variables/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.switcherWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
