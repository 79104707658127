@import '../../assets/styles/variables/colors';
@import '../../assets/styles/mixin/breakpoints';

.text {
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white-80;

  @include xl {
    font-size: 16px;
    line-height: 20px;
  }
}
