@import '../../../assets/styles/mixin/breakpoints';

.wrapper {
  display: flex;
  align-items: center;
  align-self: flex-end;
  column-gap: 16px;

  @include lg {
    flex-wrap: wrap;
  }

  @include md {
    align-self: unset;
  }

  @include sm {
    justify-content: center;
  }
}
