@import '../../../../../../../assets/styles/variables/colors';

.wrapper {
  padding: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  background: $white-3;
  border-radius: 12px;
}

.label {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: $white-80;
}
