@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
}

.button {
  position: absolute;
  left: 0;
  width: 40px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white-3;
  border-radius: 12px;
}

.heading {
  width: 100%;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white;

  @include md {
    font-size: 18px;
  }
}
