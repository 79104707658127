@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.btnWrapper {
  width: 100%;
  max-width: 334px;
  height: 57px;

  @include lg {
    align-self: center;
  }

  @include sm {
    max-width: 100%;
  }
}
