@import './src/assets/styles/variables/colors';

.activeLink {
  width: calc(100% + 27px);
  border-right: 2px solid $pink;
  border-radius: 12px 2px 2px 12px;
  background: linear-gradient(0deg, $pink-10, $pink-10), $black-nav-bg;

  .icon svg path {
    stroke: $pink;
  }

  .label {
    color: $white;
  }
}

.link:nth-child(4),
.link:last-child {
  .icon svg path {
    fill: $grey-nav;
  }
}

.link:nth-child(4).activeLink {
  .icon svg path {
    fill: $pink;
  }
}

.wrapper {
  padding: 16px 24px;
  min-height: 54px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.label {
  white-space: nowrap;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $grey-nav;
}

.icon svg {
  width: 16px;

  path {
    stroke: $grey-nav;
  }
}
