@import './src/assets/styles/variables/colors';

.inputChildren {
  padding: 8px 4px;
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translate(0, -50%);
  background: $white-3;
  border-radius: 12px;
}

.input {
  text-align: right;
}
