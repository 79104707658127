@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.label {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: $white-50;

  @include xl {
    font-size: 12px;
  }
}

.valueWrapper {
  display: flex;
  gap: 4px;
}

.value {
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.015em;
  color: $white;

  @include xl {
    font-size: 16px;
  }
}

.addInfo {
  font-weight: 800;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.015em;
  color: $white-50;
}
