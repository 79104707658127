@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 5px;

  @include xl {
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 16px;

    & > div:nth-of-type(3) {
      grid-column-start: 1;
      grid-column-end: 5;
      grid-row-start: 2;
    }
  }

  @include lg {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: unset;

    & > div:nth-of-type(3) {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 3;
    }
  }
}
