.wrapper {
  cursor: pointer;
  display: flex;
  justify-content: end;
  gap: 24px;
  width: 100%;
}

.icon {
  display: flex;
}
