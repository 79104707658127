@import './src/assets/styles/mixin/breakpoints';
@import './src/assets/styles/variables/colors';

.wrapper {
  display: flex;
  gap: 8px;
}

.input {
  text-align: center;
}

.buttonWrapper {
  min-width: 64px;
}

.inputControl {
  @include xxxxl {
    display: none;
  }
}

.error {
  margin-top: 8px;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.015em;
  text-align: right;
  text-transform: capitalize;
  color: $red;
}
