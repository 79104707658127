@import './src/assets/styles/variables/colors';

::-webkit-scrollbar {
  width: 4px !important;
}

::-webkit-scrollbar-track {
  background: $white-5 !important;
  border-radius: 2px !important;
  box-shadow: none !important;
}

::-webkit-scrollbar-thumb {
  background: $white-10 !important;
  border-radius: 2px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: $white-10 !important;
}
