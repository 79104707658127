@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.inputWrapper {
  position: relative;
  width: 100%;
  height: 64px;
}

.input {
  width: 100%;
  height: 100%;
  padding: 16px;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  text-align: left;
  text-transform: capitalize;
  color: $white-50;
  background-color: transparent;
  border-radius: 16px;
  border: 1px solid $grey-input;
  outline: none;
  transition: 0.8s;
  &:not([value='']) {
    font-weight: 500;
    color: $white;
  }

  &:hover {
    padding: 17px;
    font-weight: 500;
    color: $white-70;
    border: 1px solid $grey-nav;
    transition: 0.8s;
  }

  &:focus {
    padding: 16px;
    font-weight: 500;
    color: $white;
    border: 1px solid $grey-nav;
  }

  &::placeholder {
    font-weight: 300;
    color: $white-50;
  }

  @include xl {
    font-size: 16px;
    line-height: 20px;
  }
}

.inputError {
  border: 1px solid $red;

  &:hover {
    border: 1px solid $red;
  }

  &:focus {
    border: 1px solid $red;
  }
}

.heading {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white-50;
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.error {
  margin-top: 8px;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.015em;
  text-align: right;
  text-transform: capitalize;
  color: $red;
}
