@import './src/assets/styles/variables/colors';

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: $white-50;
}

.valueWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.value {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  letter-spacing: -0.015em;
  color: $white-80;
}

.button {
  display: flex;
  path {
    stroke: $pink;
  }
}
