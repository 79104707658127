@import './src/assets/styles/variables/colors';

.inputWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.inputWrapperReverse {
  flex-direction: column-reverse;
}

.button {
  padding: 4px 8px;
  display: flex;
  background: $white-3;
  border-radius: 12px;
}

.error {
  margin-top: 8px;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.015em;
  text-align: right;
  text-transform: capitalize;
  color: $red;
}
