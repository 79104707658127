@import '../../../../../../assets/styles/variables/colors';

.listFav {
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid $white-5;
}

.itemsList {
  height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
