@import './src/assets/styles/variables/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.optionsWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.error {
  margin-top: 8px;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.015em;
  text-align: right;
  text-transform: capitalize;
  color: $red;
}
