@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.buttonWrapper {
  height: 57px;
}

.buttonBlock {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.toleranceWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.value {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  letter-spacing: -0.015em;
  color: $white-80;
}

.modalWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.subheading {
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white-80;

  @include xl {
    font-size: 16px;
    line-height: 20px;
  }
}

.valueWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.valueHeading {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: $white-50;

  @include xl {
    font-size: 12px;
    line-height: 15px;
  }
}

.value {
  font-weight: 800;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.015em;
  color: $white;

  @include xl {
    font-size: 16px;
    line-height: 20px;
  }
}

.modalLink {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: $white-80;

  @include xl {
    font-size: 14px;
  }
}
