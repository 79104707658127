@font-face {
  font-family: 'Oxanium';
  src: url('../../fonts/Oxanium-ExtraLight.ttf');
  font-weight: 200;
}

@font-face {
  font-family: 'Oxanium';
  src: url('../../fonts/Oxanium-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Oxanium';
  src: url('../../fonts/Oxanium-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Oxanium';
  src: url('../../fonts/Oxanium-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Oxanium';
  src: url('../../fonts/Oxanium-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Oxanium';
  src: url('../../fonts/Oxanium-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Oxanium';
  src: url('../../fonts/Oxanium-ExtraBold.ttf');
  font-weight: 800;
}
