@import '../../assets/styles/variables/colors';
@import '../../assets/styles/mixin/breakpoints';

.wrapper {
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
  background: $black-footer;

  @include md {
    align-items: center;
    gap: 16px;
  }
}
