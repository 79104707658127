@import '../../assets/styles/variables/colors';

.container {
  width: 100%;
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.text {
  width: 30px;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: $white-80;
}

.icon svg {
  width: 24px;
}
