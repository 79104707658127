@import '../../../../../../../assets/styles/variables/colors';

.text {
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white;
}
