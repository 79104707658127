@import './src/assets/styles/variables/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background: $white-5;
  border-radius: 16px;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headingText {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: $white;
  white-space: pre-wrap;
}

.contentWrapper {
  display: none;
}

.contentWrapperActive {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.valueWrapper {
  display: flex;
  justify-content: space-between;
}

.valueHeading,
.value {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  white-space: pre-wrap;
}

.valueHeading {
  color: $white-50;
}

.value {
  color: $white;
}

.buttonWrapper {
  display: flex;
  gap: 16px;
}

.button {
  width: 100%;
  height: 57px;
}
