@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
}

.headingBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.badge {
  padding: 8px;
  font-weight: 700;
  font-size: 16px;
  color: $white;
  background: $red;
  border-radius: 8px;

  @include xl {
    font-size: 14px;
  }
}

.headingWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.heading {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.015em;
  color: $white;

  @include xl {
    font-size: 16px;
  }
}

.description {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.015em;
  color: $white-50;
}

.valueWrapper {
  display: flex;
}

.valueHeading {
  width: 130px;
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.015em;
  color: $white-50;

  @include xl {
    font-size: 12px;
  }
}

.value {
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.015em;
  color: $white-90;

  overflow: hidden;
  text-overflow: ellipsis;

  @include xl {
    font-size: 12px;
  }
}

.text {
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.015em;
  color: $white-50;

  overflow: hidden;
  text-overflow: ellipsis;

  @include xl {
    font-size: 12px;
  }
}
