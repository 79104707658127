@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.radioWrapper {
  display: flex;
  gap: 24px;

  @include sm {
    flex-direction: column;
  }
}
