@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  width: 100%;
  height: 100%;
  padding: 24px 0;
  display: grid;
  grid-template-columns: 334px 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 40px;

  @include xl {
    grid-template-columns: 230px 1fr;
  }

  @include lg {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
  }

  @include md {
    grid-template-columns: 1fr;
    grid-column-gap: unset;
  }
}

.inputBlock {
  display: flex;
  flex-direction: column;
  gap: 24px;
  grid-column-start: 1;

  @include lg {
    grid-row-start: 1;
  }
}

.checkoutWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include lg {
    grid-row-start: 1;
  }
}

.flexGap {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.btnWrapper {
  height: 57px;
}

.checkoutBlock {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  grid-column-start: 1;

  @include lg {
    grid-column-start: 2;
    justify-content: flex-start;
  }

  @include md {
    grid-column-start: 1;
    grid-row-start: 2;
    justify-content: flex-end;
  }
}

.headingWrapper {
  @include md {
    display: flex;
    justify-content: center;
  }
}
