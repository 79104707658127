@import './src/assets/styles/variables/colors';

.react-select-container .react-select {
  &__control {
    min-height: 55px;
    margin: -1px;
    background: transparent;
    border: 1px solid $grey-input;
    border-radius: 16px;
    transition: 0.8s;
    &:hover,
    &:focus {
      border: 1px solid $grey-nav;
      color: $white;
    }
  }

  &__control--is-focused {
    border: 1px solid $grey-nav;
    box-shadow: none;
  }

  &__indicator-separator {
    display: none;
  }

  &__placeholder {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.015em;
    text-transform: capitalize;
    color: $white-50;
  }

  &__indicator path {
    color: $grey-input;
  }

  &__menu {
    background: $black-bg;
    border-radius: 16px;
    box-shadow: none;
  }

  &__option {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.015em;
    text-transform: capitalize;
    color: $white-50;
    border-radius: 16px;
  }

  &__option--is-focused {
    cursor: pointer;
    background-color: $black-15;
    border-radius: 16px;
  }

  &__option--is-selected {
    cursor: pointer;
    color: $white;
    background-color: unset;
    border-radius: 16px;
  }
}
