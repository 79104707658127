@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.text {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white-50;

  @include xl {
    font-size: 12px;
    line-height: 15px;
  }
}
