@import './src/assets/styles/variables/colors';
@import './src/assets/styles/mixin/breakpoints';

.wrapper {
  display: flex;
  gap: 16px;

  @include xl {
    flex-direction: column;
  }
}

.inputWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.buttonWrapper {
  margin-top: 8px;
  text-align: right;
}

.button {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $pink;
}

.headingWrapper {
  display: flex;
  justify-content: space-between;
}

.heading,
.valueWrapper {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white-50;
}

.value {
  color: $white;
}

.valueWrapper {
  display: flex;
  align-items: center;
}

.buttonGradientWrapper {
  margin-top: 24px;
  width: 141px;
  height: 64px;

  @include xl {
    width: 100%;
    margin-top: 0;
  }

  @include md {
    height: 44px;
  }
}

.error {
  margin-top: 8px;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.015em;
  text-align: right;
  text-transform: capitalize;
  color: $red;
}
