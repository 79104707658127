@import '../../../assets/styles/variables/colors';

.wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.logoWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.text {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $white;
}
